<template>
  <div>
    <PageHeader />
    <b-card class="mb-4">
      <h5>New Refill Promotion</h5>
      <ValidationObserver ref="refillPromotionForm" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <AgentsSelectInput
            :value="refillPromotionForm.agentId"
            :readonly="false"
            @update="onAgentIdChange"
          />
          <b-form-group
            label="Promo title"
            label-cols-sm="12"
            label-cols-lg="3"
          >
            <b-input
              v-model="refillPromotionForm.title"
              placeholder="เติมเงินครบ 300 ได้รับ 5 คูปอง"
              type="text"
            ></b-input>
          </b-form-group>
          <b-form-group
            label="Required refills"
            label-cols-sm="12"
            label-cols-lg="3"
          >
            <b-input
              v-model="refillPromotionForm.requiredRefills"
              placeholder="จำนวนเงินที่ต้องเติม"
              type="number"
            ></b-input>
          </b-form-group>
          <b-form-group
            label="Coupon Earns"
            label-cols-sm="12"
            label-cols-lg="3"
          >
            <b-input
              v-model="refillPromotionForm.couponEarns"
              placeholder="คูปองที่ได้รับเมื่อทำโปรสำเร็จ"
              type="number"
            ></b-input>
          </b-form-group>
          <div class="text-right">
            <b-button variant="light" to="/rewards/list" class="mr-2">
              {{ $t('buttons.cancel') }}
            </b-button>
            <b-overlay
              rounded
              spinner-small
              class="d-inline-block"
              :show="isCreating"
            >
              <b-button type="submit" variant="primary">
                {{ $t('buttons.confirm') }}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  page: {
    title: 'Refill promotion create',
  },
  components: {
    AgentsSelectInput: () =>
      import('../../../components/agents/agents-select-input'),
  },
  data() {
    return {
      refillPromotionForm: {
        agentId: null,
        title: '',
        couponEarns: 1,
        requiredRefills: 0,
      },
    }
  },
  computed: {
    ...mapState({
      isCreating: (state) => state.reward.isCreatingRefillPromotion,
      isUpdating: (state) => state.reward.isUpdatingRefillPromotion,
      refillPromotionDetail: (state) => state.reward.refillPromotionDetail,
    }),
    agentId() {
      return this.$route.query.agentId || this.userInfo.agentId
    },
    conditionId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
  },
  watch: {
    refillPromotionDetail(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    if (this.conditionId) {
      this.fetchRefillPromotionById(this.conditionId)
    }
  },
  methods: {
    ...mapActions([
      'createRefillPromotion',
      'fetchRefillPromotionById',
      'updateRefillPromotion',
    ]),
    setData() {
      if (this.refillPromotionDetail) {
        const data = JSON.parse(JSON.stringify(this.refillPromotionDetail))
        this.refillPromotionForm = { ...this.refillPromotionForm, ...data }
        if (this.agentId) {
          this.refillPromotionForm.agentId = this.agentId
        }
      } else {
        this.refillPromotionForm = {
          agentId: null,
          title: '',
          couponEarns: 1,
          requiredRefills: 0,
        }
      }
    },
    onAgentIdChange(agentId) {
      this.refillPromotionForm.agentId = agentId
    },
    async onSubmit() {
      if (this.conditionId) {
        this.updateRefillPromotion({
          id: this.conditionId,
          data: this.refillPromotionForm,
        })
      } else {
        await this.createRefillPromotion({
          data: this.refillPromotionForm,
          agentId: this.refillPromotionForm.agentId
            ? this.refillPromotionForm.agentId
            : this.agentId,
        })

        this.$router.push('/rewards/list')
      }
    },
  },
}
</script>
